<template>
  <div class="login">
    <div class="login_content">
      <h4>微信登录</h4>
      <img :src="codeUrl" alt="" />
      <p>请使用微信扫描二维码登录</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      codeUrl: "",
    };
  },
  mounted() {
    const token = this.$route.query.token;
    // console.log("》》》 ",window.localStorage.getItem("linkurl"));
    if (token) {
      this.global.year = "2023sz";
      window.localStorage.setItem("token", token);
      if(window.localStorage.getItem("linkurl")){
        let h = window.localStorage.getItem("linkurl");
        window.localStorage.removeItem("linkurl");
        this.$router.push( h );
      } else {
        this.$router.push({ path: "/2023sz/home" })
      }
    } else {
      this.memberLogin(); // 跳转member
    }
  },
  methods: {
    // 跳转member进行登录(新登录方式[2021-11])
    memberLogin() {
      const token = window.localStorage.getItem("token", token);

      if (token === undefined || !token) {
        let href = `https://member.msup.com.cn/login?type=a2m&url=${encodeURIComponent(
          window.location.href
        )}`;
        window.location.href = href;
      } else {
        if(window.localStorage.getItem("linkurl")){
          let h = window.localStorage.getItem("linkurl");
          window.localStorage.removeItem("linkurl")
          this.$router.push( h );
        } else {
          this.$router.push({ path: "/2023sz/home" })
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 767px) {
  .login {
    margin-top: 150px;
  }
}
@media (max-width: 767px) {
  .login {
    margin-top: 2rem;
  }
}
.login {
  width: 100%;
  height: calc(100vh - 150px);
  padding: 50px;
  position: fixed;
  background-color: #fff;
  .login_content {
    text-align: center;
    h4 {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
    }
    img {
      width: 280px;
      margin-top: 15px;
      border: 1px solid #e2e2e2;
    }
    p {
      margin-top: 15px;
      margin-left: auto;
      margin-right: auto;
      background-color: #232323;
      border-radius: 100px;
      -moz-border-radius: 100px;
      -webkit-border-radius: 100px;
      box-shadow: inset 0 5px 10px -5px #191919, 0 1px 0 0 #444;
      -moz-box-shadow: inset 0 5px 10px -5px #191919, 0 1px 0 0 #444;
      width: 280px;
      padding-top: 4px;
      padding-bottom: 4px;
      font-size: 13px;
      line-height: 30px;
      font-weight: bold;
      color: #fff;
    }
  }
}
</style>
